<script setup lang="ts">
defineProps<{ activeIndex: number; length: number }>();

const emit = defineEmits<{ (e: 'dotClick', i: number): void }>();
</script>

<template>
  <span class="inline-flex gap-8">
    <button
      class="btn-plain bg-primary w-[10px] h-[10px] rounded-2"
      v-for="i in length"
      :aria-label="`Slide to ${i}`"
      :class="{
        'opacity-100': activeIndex === i - 1,
        'opacity-20': activeIndex !== i - 1,
      }"
      :key="i"
      @click="emit('dotClick', i - 1)"
    ></button>
  </span>
</template>
